"use client";
//import { supabase } from './clientSupabase'
import { SignObj } from '@/types/database_types'
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
const supabase = createClientComponentClient()

export async function SignUp(request: SignObj): Promise<{ data: any, error: any, emailIsTakenMessage: string | undefined }> {

    if (!request.email || !request.password) {
        return { data: null, error: new Error("Email or password is missing"), emailIsTakenMessage: undefined };
    }

    let { data, error } = await supabase.auth.signUp({
        email: request.email,
        password: request.password,
        options: {
            emailRedirectTo: `${location.origin}/auth/callback`
        }
    })

    let emailIsTakenMessage;
    if (data && data.user) {
        const emailIsTaken = data.user.identities?.length === 0
        if (emailIsTaken) {
            emailIsTakenMessage = "L'adresse email est déjà prise."
        }
    }


    return { data, error, emailIsTakenMessage };
}

export async function SignIn(request: SignObj): Promise<{ data: any, error: any }> {
    if (!request.email || !request.password) {
        return { data: null, error: new Error("Email or password is missing") };
    }

    let { data, error } = await supabase.auth.signInWithPassword({
        email: request.email,
        password: request.password,
    })

    return { data, error };
}


export async function SignOut(): Promise<{ error: any }> {
    let { error } = await supabase.auth.signOut()
    return { error };
}

export async function PasswordRecovery(email: string): Promise<{ data: any, error: any }> {

    let { data, error } = await supabase.auth.resetPasswordForEmail(
        email
    )

    return { data, error };
}

export async function UpdateUser(request: SignObj): Promise<{ data: any, error: any }> {
    console.log("🚀 ~ file: signFunctions.ts:54 ~ UpdateUser ~ request:", request)
    let data: any = null, error: any = null;

    if (request.email && !request.password) {
        ({ data, error } = await supabase.auth.updateUser({
            email: request.email,
        }));
    } else if (request.password && !request.email) {
        ({ data, error } = await supabase.auth.updateUser({
            password: request.password
        }));
    } else if (request.email && request.password) {
        ({ data, error } = await supabase.auth.updateUser({
            email: request.email,
            password: request.password
        }));
    } else {
        // Handle case when neither email nor password is provided
        error = new Error("Neither email nor password provided.");
    }

    if (error) {
        console.log("🚀 ~ file: signFunctions.ts:57 ~ UpdateUser ~ error:", error);
    }

    return { data, error };
}



import { createServerComponentClient } from "@supabase/auth-helpers-nextjs"
import { redirect } from "next/navigation";

export async function RedirectIfUnauth(cookies: any) {
    const supabase = createServerComponentClient({ cookies });

    const {
        data: { session },
    } = await supabase.auth.getSession();

    if (!session) {
        redirect("/");
    }
}