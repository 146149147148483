import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// 1. Initial state
const initialState: {
    profilSelected: number | string;
} = {
    profilSelected: 1
};

// 2. Slice
const toolIdSelectProfilSice = createSlice({
    name: 'profil',
    initialState,
    reducers: {
        setProfilSelected: (state, action: PayloadAction<number | string>) => {
            state.profilSelected = action.payload;
        }
    }
});

// 3. Exporter les actions et le reducer
export const { setProfilSelected } = toolIdSelectProfilSice.actions;
export default toolIdSelectProfilSice.reducer;
