import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TokenState {
    value: number;
}

const initialState: TokenState = { value: 0 };

const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        updateToken: (state, action: PayloadAction<number>) => {
            state.value = action.payload;
        },
    },
});

export const { updateToken } = tokenSlice.actions;

export default tokenSlice.reducer;