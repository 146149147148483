//import { supabase } from './clientSupabase'
import { ProfilesNicheBaseTypes, ProfilesNicheCustomTypes } from '../types/database_types'
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
const supabase = createClientComponentClient()

export async function GetAllProfils(): Promise<ProfilesNicheBaseTypes[] | Error> {
    let { data: profilesNicheBase, error } = await supabase
        .from('profilesNicheBase')
        .select('*')

    if (profilesNicheBase) {
        return profilesNicheBase as ProfilesNicheBaseTypes[]
    }
    if (error) {
        console.error(error)
        return new Error(error.message);
    }
    return new Error("An unexpected error occurred");
}

export async function GetToolsFromProfil(profilId: number) {
    let { data: tools_profilesNicheBase, error } = await supabase
        .from('tools_profilesNicheBase')
        .select('tools_Id')

        // Filters
        .eq('profilesNicheBase_Id', profilId)
    if (tools_profilesNicheBase) {
        return tools_profilesNicheBase
    }
    if (error) {
        console.error(error)
        return error
    }
}

export async function DeleteToolFromProfil(toolId: string | number, profilId: string) {

    if (typeof toolId === 'number') {
        const { error } = await supabase
            .from('tools_profilesNicheCustom')
            .delete()
            .eq('profilesNicheCustom_id', profilId)
            .eq('tools_Id', toolId)
        if (error) {
            console.error(error)
            return error
        }
    } else {
        const { error } = await supabase
            .from('tools_profilesNicheCustom')
            .delete()
            .eq('profilesNicheCustom_id', profilId)
            .eq('UserTools_Id', toolId)
        if (error) {
            console.error(error)
            return error
        }
    }


}

export async function GetToolsFromCustomProfil(profilId: string) {
    let { data: tools_profilesNicheCustom, error } = await supabase
        .from('tools_profilesNicheCustom')
        .select('tools_Id, UserTools_Id')

        // Filters
        .eq('profilesNicheCustom_id', profilId)
    if (tools_profilesNicheCustom) {
        return tools_profilesNicheCustom
    }
    if (error) {
        console.error(error)
        return error
    }
}

// export async function InsertToolToCustomProfil(toolId, customProfilId) {

//     const { data, error } = await supabase
//         .from('tools_profilesNicheCustom')
//         .insert([
//             { tools_Id: toolId, profilesNicheCustom_id: customProfilId },
//         ])
//         .select()
//     if (data) {
//         return data
//     }
//     if (error) {
//         console.error(error)
//         return error
//     }
// }

export async function InsertUserToolToCustomProfil(modelId: string, profilId: string) {

    const { data, error } = await supabase
        .from('tools_profilesNicheCustom')
        .insert([
            { profilesNicheCustom_id: profilId, UserTools_Id: modelId },
        ])
        .select();
    if (data) {
        return data
    }
    if (error) {
        console.error(error);
        return error;
    }

}

/* Custom Profils */
export const GetCustomProfil = async (): Promise<ProfilesNicheCustomTypes[] | Error> => {
    const {
        data: { user },
    } = await supabase.auth.getUser()

    if (user) {
        let { data: profilesNicheCustom, error } = await supabase
            .from('profilesNicheCustom')
            .select('*')
            .eq('userid', user.id)

        if (error) {
            console.error(error)
            return new Error(error.message);
        }

        return profilesNicheCustom as ProfilesNicheCustomTypes[]
    } else {
        console.error("user is undefinded")
    }
    return new Error("An unexpected error occurred");

}


export async function CreateCustomProfil(name?: string) {
    const {
        data: { user },
    } = await supabase.auth.getUser()

    if (user) {
        const { data, error } = await supabase
            .from('profilesNicheCustom')
            .insert([
                { userid: user.id, profileName: name }
            ])
            .select()

        if (error) {
            console.error(error)
            return error
        }
        return data
    } else {
        console.error("user is undefinded")
    }

}

export async function InsertToolToCustomProfil(profilId: string, toolId: number) {
    if (isNaN(Number(toolId))) {
        const { data, error } = await supabase
            .from('tools_profilesNicheCustom')
            .insert([
                { profilesNicheCustom_id: profilId, UserTools_Id: toolId },
            ])
            .select();

        if (error) {
            console.error(error);
            return error;
        }
    } else {
        const { data, error } = await supabase
            .from('tools_profilesNicheCustom')
            .insert([
                { profilesNicheCustom_id: profilId, tools_Id: toolId },
            ])
            .select();

        if (error) {
            console.error(error);
            return error;
        }

    }

    return "All data successfully inserted";
}


export async function UpdateCustomProfil(id: string, name?: string) {

    const { data, error } = await supabase
        .from('profilesNicheCustom')
        .update({ profileName: name })
        .eq('id', id)
        .select()

    if (error) {
        console.error(error)
        return error
    }
    return data
}

export async function DeleteCustomProfil(profilId: string) {
    console.log("🚀 ~ file: profils.ts:200 ~ DeleteCustomProfil ~ profilId:", profilId)

    const { error } = await supabase
        .from('profilesNicheCustom')
        .delete()
        .eq('id', profilId)

    if (error) {
        console.error(error)
        return error
    }
}
