//import { supabase } from './clientSupabase'
//import { cookies } from 'next/headers';
// const supabase1 = createServerComponentClient({ cookies })
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
const supabase = createClientComponentClient()

export async function GetUserEmail() {
    let { data: { user } } = await supabase.auth.getUser()

    if (user && user.email) {
        return user.email;
    } else {
        console.error("user email is missing")
    }
}


export async function GetUserData() {
    let { data: { user } } = await supabase.auth.getUser()
    return user;
}

export async function userIsLoggedIn(router: any) {
    const userdata = await GetUserData();
    if (!userdata) {
        router.push('/')
    } if (userdata && (window.location.pathname === "/")) {
        router.push('/welcome')
    } else {
        return
    }
}

export async function userIsLoggedInLanding() {
    const userdata = await GetUserData();
    if (!userdata) {
        return false
    } else {
        return true
    }
}

export async function GetUserTokensLeft() {


    try {
        let { data: profiles, error } = await supabase
            .from('profiles')
            .select('tokens')

        if (error) throw error;

        return profiles;
    } catch (error) {
        console.error("Une erreur s'est produite lors de la récupération des tokens : ", error);
        throw error; // ou peut-être vous voulez retourner null, undefined ou une valeur par défaut
    }
}

export async function GetUserProfile() {
    const {
        data: { user },
    } = await supabase.auth.getUser()


    if (user) {
        try {
            let { data: profiles, error } = await supabase
                .from('profiles')
                .select('*')
                .eq('id', user.id)
            if (error) throw error;

            return profiles;
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des tokens : ", error);
            throw error;
        }
    } else {
        console.error("user is undefinded")
    }

}