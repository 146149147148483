import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetCustomProfil, GetAllProfils } from '@/utils/profils';
import { GetAllTools, GetAllUserTools } from '@/utils/tools';
import { ProfilesNicheCustomTypes, ToolsTypes, ProfilesNicheBaseTypes, UserToolsTypes } from '@/types/database_types';
import { PostgrestError } from '@supabase/supabase-js';


interface DataState {
    customProfils: ProfilesNicheCustomTypes[] | Error;
    tools: any;
    profils: Error | ProfilesNicheBaseTypes[];
    userModels: Error | UserToolsTypes[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    profilPlaceholder: string | null;
}

const initialState: DataState = {
    customProfils: [],
    tools: [],
    profils: [],
    userModels: [],
    status: 'idle',
    error: null,
    profilPlaceholder: null
};

export const fetchDataAsync = createAsyncThunk(
    'data/fetchData',
    async () => {
        const customProfils = await GetCustomProfil();
        const tools = await GetAllTools();
        const profils = await GetAllProfils();
        const userModels = await GetAllUserTools();

        return { customProfils, tools, profils, userModels };
    }
);

const toolIdProfilsSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchDataAsync.pending, state => {
                state.status = 'loading';
            })
            .addCase(fetchDataAsync.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.customProfils = action.payload.customProfils;
                state.tools = action.payload.tools;
                state.profils = action.payload.profils;
                state.userModels = action.payload.userModels;

                if (Array.isArray(action.payload.customProfils) && action.payload.customProfils[0]?.profileName) {
                    state.profilPlaceholder = action.payload.customProfils[0].profileName;
                }
            })
            .addCase(fetchDataAsync.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message || null;
            });
    }
});

export default toolIdProfilsSlice.reducer;
