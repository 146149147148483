//import { supabase } from './clientSupabase'
import { UserToolsTypes, formDataType, ToolsTypes } from '../types/database_types'

import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
const supabase = createClientComponentClient()

export async function GetAllTools() {
    let { data: Tools, error } = await supabase
        .from('Tools')
        .select('id,toolName,description,logoSrc,userInputs,category')
    if (Tools) {
        return Tools as ToolsTypes[];
    }
    if (error) {
        console.error(error)
        return error
    }
}

export async function GetOneTool(toolId: number) {
    let { data: Tools, error } = await supabase
        .from('Tools')
        .select('id,toolName,description,logoSrc,userInputs')

        // Filters
        .eq('id', toolId)
    if (Tools) {
        return Tools
    }
    if (error) {
        console.error(error)
        return error
    }
}

export const replaceVariablesInString = (text: string, object: formDataType): string => {
    let replacedText = text;

    // Read object keys
    Object.keys(object).forEach((key) => {
        if (key !== "model" && key !== "temperature" && key !== "language") {
            const regex = new RegExp(`{${key}}`, 'g');
            replacedText = replacedText.replace(regex, object[key]);
        }
    });

    return replacedText;
}

/* Custom Tools */
export async function GetOneCustomUserTool(modelId: string) {
    let { data: UserTools, error } = await supabase
        .from('UserTools')
        .select('*')

        // Filters
        .eq('id', modelId)
    if (UserTools) {
        return UserTools
    }
    if (error) {
        console.error(error)
        return error
    }
}



/* Create custom prompt */
export async function GetAllUserTools(): Promise<UserToolsTypes[] | Error> {
    let { data: UserTools, error } = await supabase
        .from('UserTools')
        .select('*')

    if (UserTools) {
        return UserTools as UserToolsTypes[]
    }
    if (error) {
        console.error(error);
        return new Error(error.message);
    }
    return new Error("An unexpected error occurred");
}

export async function DeleteUserTools(userToolId: string) {
    const { error } = await supabase
        .from('UserTools')
        .delete()
        .eq('id', userToolId)

    if (error) {
        console.error(error)
        return error
    }
}


export async function UpdateUserTools(userToolId: string, name: string | null, prompt: string | null, logo: string) {


    const { data, error } = await supabase
        .from('UserTools')
        .update({ prompt: prompt, name: name, logoSrc: logo })
        // .eq('userId', user.id)
        .eq('id', userToolId)
        .select()


    if (error) {
        console.error(error)
        return error
    }
    return data
}

export async function CreateUserTools(name: string | null, prompt: string | null, logo?: string): Promise<UserToolsTypes[] | Error> {
    const {
        data: { user },
    } = await supabase.auth.getUser()

    if (user) {
        const { data, error } = await supabase
            .from('UserTools')
            .insert([{ prompt: prompt, name: name, userid: user.id, logoSrc: logo }])
            .select()

        if (data) {
            return data as UserToolsTypes[]
        }

        if (error) {
            console.error(error);
            return new Error(error.message);
        }
    } else {
        console.error("user is undefinded")
        return new Error("user is undefined");
    }
    return new Error("An unexpected error occurred");
}