import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MenuState {
    isClosed: boolean;
    submenus: { [key: string]: boolean };
}


const initialState: MenuState = {
    isClosed: false,
    submenus: {},
};

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        toggleMenu: (state) => {
            state.isClosed = !state.isClosed;
        },
        closeMenu: (state) => {
            state.isClosed = true;
        },
        openMenu: (state) => {
            state.isClosed = false;
        },
        toggleSubmenu: (state, action: PayloadAction<string>) => {
            const submenuName = action.payload;
            if (state.submenus[submenuName] === undefined) {
                state.submenus[submenuName] = true;
            } else {
                state.submenus[submenuName] = !state.submenus[submenuName];
            }
        },
        closeSubmenu: (state, action: PayloadAction<string>) => {
            state.submenus[action.payload] = true;
        },
        openSubmenu: (state, action: PayloadAction<string>) => {
            state.submenus[action.payload] = false;
        },
    },
});

export const { toggleMenu, closeMenu, openMenu, toggleSubmenu, closeSubmenu, openSubmenu } = menuSlice.actions;

export default menuSlice.reducer;
