import { configureStore } from '@reduxjs/toolkit';
import tokenReducer from './features/tokenSlice';
import menuReducer from './features/menuSlice';
import { createWrapper } from 'next-redux-wrapper';
import toolIdProfilsReducer from './features/toolIdProfils';
import toolIdSelectProfilSiceReducer from './features/toolIdSelectProfilSice';

export const store = configureStore({
    reducer: {
        token: tokenReducer,
        menu: menuReducer,
        toolIdProfils: toolIdProfilsReducer,
        toolIdSelectProfilSice: toolIdSelectProfilSiceReducer
    },
});


const makeStore = () =>
    configureStore({
        reducer: {
            token: tokenReducer,
            menu: menuReducer,
            toolIdProfils: toolIdProfilsReducer,
            toolIdSelectProfilSice: toolIdSelectProfilSiceReducer
        },
        devTools: true,
    });

export const wrapper = createWrapper(makeStore);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

